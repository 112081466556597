import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

import { install } from '@/install'
import { createApp } from '@/app'

import './styles/index.sass'

import '@app/js/directives'
import '@app/js/validation'

Vue.config.productionTip = false

Vue.use(install)

const { app } = createApp({
  Vue,
  I18n,
  VueRouter,
  Vuex,
})

app.$mount('#app')

export { app }
