/**
 * get cookie
 * @param {*} name
 */
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

const bakeCookie = (name, value) => {
  const now = new Date()
  const month = 12
  const days = 30
  now.setDate(month * days)
  const cookie = [name, '=', JSON.stringify(value), ';expires', '=', now.toUTCString(), '; path=/;'].join('')
  document.cookie = cookie
}

/**
 * set cookie without expired date
 * @param {*} name
 * @param {*} val
 */
export function setCookie(name, val) {
  bakeCookie(name, val)
}
