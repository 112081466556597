import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { app } from '@app/main'

Object.entries(rules).forEach(([rule, validation]) => {
  extend(rule, {
    ...validation,
  })
})

// extend('phoneNumber', {
//   validate(value) {
//     const phoneLength = 12
//     return phoneLength === value.replace(/\D/g, '').toString().length
//   },
//   message() {
//     return app._('Введіть повний номер телефону.')
//   },
// })

extend('required', {
  ...rules.required,
  message() {
    return app._('This field is required.')
  },
})

extend('confirmed', {
  ...rules.confirmed,
  message() {
    return app._('Passwords must match.')
  },
})

// extend('email', {
//   ...rules.email,
//   message() {
//     return app._('Поле має бути адресою електронної пошти.')
//   },
// })

extend('min_value', {
  ...rules.min_value,
  message(value, sec) {
    return app._('The amount must be greater ') + sec.min
  },
})

extend('min', {
  ...rules.min,
  message(value, sec) {
    return app._('The number of characters must be at least ') + sec.length
  },
})

// extend('max', {
//   ...rules.max,
//   message(value, sec) {
//     return app._('Кількість символів має бути не більше ') + sec.length
//   },
// })

extend('alpha', {
  ...rules.alpha,
  message() {
    return app._('The field can be just letters')
  },
})

// extend('numeric', {
//   ...rules.numeric,
//   message() {
//     return app._('Поле може містити тільки цифри')
//   },
// })
