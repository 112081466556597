import {
  postResource,
  createResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'cart'

const CART_CHANGE_URL = prefixAPI('/change/', MODEL)
const CART_CLEAR_URL = prefixAPI('/clear/', MODEL)
const CART_RETRIEVE_URL = prefixAPI('/retrieve/', MODEL)
const CART_INFO_URL = prefixAPI('/quantity/', MODEL)

export const cartChangeLink = createResource(
  CART_CHANGE_URL, postResource
)
export const cartCleanLink = createResource(
  CART_CLEAR_URL, postResource
)

export const getCartRetrieve = createResource(CART_RETRIEVE_URL)
export const getCartInfo = createResource(CART_INFO_URL)
