const Renderer = () => import('./Renderer')
const ModalTrigger = () => import('./ModalTrigger')
const SidebarTrigger = () => import('./SidebarTrigger')
const ShowMoreTrigger = () => import('./ShowMoreTrigger')
const UiTabs = () => import('./UiTabs')
const UiSlider = () => import('./UiSlider')
const UiGallery = () => import('./UiGallery')
const LogoutTrigger = () => import('./LogoutTrigger')
const UiZoomer = () => import('./UiZoomer')
const UiClickOutside = () => import('./UiClickOutside')
const UiDropdown = () => import('./UiDropdown')
const WrapperLink = () => import('./WrapperLink')

export default function installComponents(Vue) {
  Vue.component('Renderer', Renderer)
  Vue.component('ModalTrigger', ModalTrigger)
  Vue.component('SidebarTrigger', SidebarTrigger)
  Vue.component('UiSlider', UiSlider)
  Vue.component('UiTabs', UiTabs)
  Vue.component('UiGallery', UiGallery)
  Vue.component('ShowMoreTrigger', ShowMoreTrigger)
  Vue.component('LogoutTrigger', LogoutTrigger)
  Vue.component('UiZoomer', UiZoomer)
  Vue.component('UiClickOutside', UiClickOutside)
  Vue.component('UiDropdown', UiDropdown)
  Vue.component('WrapperLink', WrapperLink)
}
