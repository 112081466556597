import { createI18n } from '@/i18n'
import { createStore } from '@/js/store'
import { createRouter } from '@/js/router'
import { setCookie } from '@utils/cookies'

export function createApp({ Vue, VueRouter, I18n, Vuex }) {
  const i18n = createI18n({ Vue, I18n })
  const router = createRouter({ Vue, VueRouter })
  const store = createStore({ Vue, Vuex })

  Vue.prototype.$CHANGE_LANGUAGE = function changeLanguage(url, langCode) {
    setCookie('CHOSEN_LANGUAGE', langCode)
    window.location.assign(url)
  }

  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })

  return { app, router, i18n, store }
}
