import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'
import BoundLabel from './BoundLabel'
import ErrorComponent from './ErrorComponent'
import AppendComponent from './AppendComponent'
import MultiSelect from './Multiselect'
// import Calendar from './DatePicker'
import StaticLabel from './StaticLabel'
// import DateRange from './DateRange'
import DatePick from './DatePick'
import UnifiedControlElement from './UnifiedControlDescriptor'

const composerForInput = (input, label = BoundLabel) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
  appendComponent: () => AppendComponent,
})(UnifiedControlElement)

const composer = (input = BoundLabel) => withDefaultProps({
  element: () => input,
  labelComponent: null,
  errorComponent: () => ErrorComponent,
  appendComponent: () => AppendComponent,
  bails: true,
  controllerComponent: () => NoneController,
})(Descriptor)
// input with bound label
const InputWithLabel = composerForInput('Input')
const ControlInput = composer(InputWithLabel)
// input with static label
const InputWithStaticLabel = composerForInput('Input', StaticLabel)
const ControlStaticInput = composer(InputWithStaticLabel)
// multiselect with bound label
const MultiselectWithLabel = composerForInput('MultiSelect')
const ControlMultiselect = composer(MultiselectWithLabel)
// multiselect with static label
const MultiselectWithStaticLabel = composerForInput('MultiSelect', StaticLabel)
const ControlStaticMultiselect = composer(MultiselectWithStaticLabel)
// textarea
const TextareaWithLabel = composerForInput('Textarea')
const ControlTextarea = composer(TextareaWithLabel)
// textarea with static label
const TextareaWithStaticLabel = composerForInput('Textarea', StaticLabel)
const ControlStaticTextarea = composer(TextareaWithStaticLabel)
// daterange
// const DateRangeWithLabel = composerForInput(DateRange)
// const ControlDateRange = composer(DateRangeWithLabel)
// picker with label
const PickerWithStaticLabel = composerForInput(DatePick, StaticLabel)
const ControlPickerStatic = composer(PickerWithStaticLabel)

export default function install(Vue, { prefix = 'DControl' } = {}) {
  Vue.component('MultiSelect', MultiSelect)
  // Vue.component('Calendar', Calendar)
  Vue.component('BoundLabel', BoundLabel)
  Vue.component('ErrorComponent', ErrorComponent)
  Vue.component('AppendComponent', AppendComponent)
  Vue.component(`${prefix}Input`, ControlInput)
  Vue.component(`${prefix}StaticInput`, ControlStaticInput)
  Vue.component(`${prefix}Multiselect`, ControlMultiselect)
  Vue.component(`${prefix}StaticMultiselect`, ControlStaticMultiselect)
  Vue.component(`${prefix}Textarea`, ControlTextarea)
  Vue.component(`${prefix}StaticTextarea`, ControlStaticTextarea)
  // Vue.component(`${prefix}DateRange`, ControlDateRange)
  Vue.component('DatePick', DatePick)
  Vue.component('DatePickLabel', ControlPickerStatic)
}
