import VueRouter from 'vue-router'
import Vue from 'vue'

import { routes as ManagerRoutes } from '@router/Manager/routes'
import { routes as BloggerRoutes } from '@router/Blogger/routes'
import { routes as CustomerRoutes } from '@router/Customer/routes'
import { notFound as PageNotFound } from '@router/NotFound/notFound'

Vue.use(VueRouter)

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  }

  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  }
}

export const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes: [
    ...ManagerRoutes,
    ...BloggerRoutes,
    ...CustomerRoutes,
    ...PageNotFound,
  ],
})
