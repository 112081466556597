import * as LINKS from '@router/Manager/consts'

const ManagerOriginComponent = () => import('@components/Cabinet/Manager/ManagerOrigin')
const ManagerToursInfoComponent = () => import('@components/Cabinet/Manager/ManagerToursInfo')

export const routes = [
  {
    path: LINKS.MANAGER_BASE_PATH,
    redirect: LINKS.MANAGER_ACTIVE_TOURS_PATH,
    component: ManagerOriginComponent,
    name: 'manager-origin',
    meta: {
      userRole: 'manager',
    },
    children: [
      {
        path: LINKS.MANAGER_ACTIVE_TOURS_PATH,
        component: ManagerToursInfoComponent,
        name: 'manager-active-tours',
      },
      {
        path: LINKS.MANAGER_ARCHIVE_TOURS_PATH,
        component: ManagerToursInfoComponent,
        name: 'manager-archive-tours',
      },
    ],
  },
]
