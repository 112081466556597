import Vue from 'vue'

Vue.directive('declension', {
  bind: (node, binding) => {
    // console.log(binding.value)
    // console.log(binding.value.count)
    // console.log(binding.value.dictionary)
    const pluralList = binding.value.dictionary
    const lang = window.language
    const countVal = binding.value.count
    const items = Array.from(node.querySelectorAll('.js-declension-text'))
    const zero = 0
    if (zero < items.length) {
      items.forEach(el => {
        const declensionText = el
        const countOfPlur = (count, keywords) => {
          const pr = new Intl.PluralRules(lang)
          const intlCount = pr.select(count)
          const collection = {
            one: `${count} ${keywords[0]}`,
            many: `${count} ${keywords[1]}`,
            few: `${count} ${keywords[2]}`,
          }
          return collection[intlCount] || collection.few
        }
        // declensionText.innerText = countOfPlur(countVal, secondPluralList[lang])
        declensionText.innerText = countOfPlur(countVal, pluralList[lang])
      })
    }
  },
})
