import Vue from 'vue'

Vue.directive('access', {
  bind(el, binding) {
    el.addEventListener('keypress', e => {
      const string = String.fromCharCode(e.which)
      const regex = new RegExp(binding.value)
      if (!string.match(regex)) {
        e.preventDefault()
      }
    })
  },
})
