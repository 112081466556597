import Vue from 'vue'

Vue.directive('tooltip-wrapper', {
  bind: node => {
    const wrapper = Array.from(node.getElementsByClassName('js-tooltip-wrapper'))
    const zero = 0
    const xl = 1200
    if (zero < wrapper.length) {
      wrapper.forEach(el => {
        el.onclick = e => {
          e.stopPropagation()
          if (window.innerWidth <= xl) {
            el.classList.toggle('is-active')
          }
        }
        const listener = e => {
          if (e.target === el || el.contains(e.target)) {
            return
          }
          el.classList.remove('is-active')
        }
        document.addEventListener('click', listener, { passive: true })
      })
    }
  },
})
