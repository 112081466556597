const BloggerOrigin = () => import('@components/Cabinet/Blogger/BloggerOrigin')
const BloggerPromocodes = () => import('@components/Cabinet/Blogger/BloggerPromocodes')
const BloggerPersonal = () => import('@components/Cabinet/Blogger/BloggerPersonal')
const BloggerTable = () => import('@components/Cabinet/Blogger/Tables/BloggerTable')
const BloggerTableBody = () => import('@components/Cabinet/Blogger/Tables/BloggerTableBody')
const BloggerTransactionsTable = () => import('@components/Cabinet/Blogger/Tables/BloggerTransactionsTable')
const FilterTransactions = () => import('@components/Cabinet/Blogger/FilterSection')

export default function installComponents(Vue) {
  Vue.component('BloggerOrigin', BloggerOrigin)
  Vue.component('BloggerPromocodes', BloggerPromocodes)
  Vue.component('BloggerPersonal', BloggerPersonal)
  Vue.component('BloggerTable', BloggerTable)
  Vue.component('BloggerTableBody', BloggerTableBody)
  Vue.component('BloggerTransactionsTable', BloggerTransactionsTable)
  Vue.component('FilterTransactions', FilterTransactions)
}
