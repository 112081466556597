const ManagerOrigin = () => import('@components/Cabinet/Manager/ManagerOrigin')
const ManagerToursInfo = () => import('@components/Cabinet/Manager/ManagerToursInfo')
const ManagerDashboard = () => import('@components/Cabinet/Manager/ManagerDashboard')
const FilterSection = () => import('@components/Cabinet/Manager/FilterSection')
const ManagerTable = () => import('@components/Cabinet/Manager/Table/ManagerTable')
const ManagerTableBody = () => import('@components/Cabinet/Manager/Table/ManagerTableBody')

export default function installComponents(Vue) {
  Vue.component('ManagerOrigin', ManagerOrigin)
  Vue.component('ManagerToursInfo', ManagerToursInfo)
  Vue.component('ManagerDashboard', ManagerDashboard)
  Vue.component('FilterSection', FilterSection)
  Vue.component('ManagerTable', ManagerTable)
  Vue.component('ManagerTableBody', ManagerTableBody)
}
