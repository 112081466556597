import ProfileManager from '@components/Cabinet/Manager'
import ProfileBlogger from '@components/Cabinet/Blogger'
import ProfileCustomer from '@components/Cabinet/Customer'
import Currencies from './Currencies'
import UiElements from './UiElements'
import Pagination from './Pagination'
import Controls from './Controls'
import CookiesInfo from './CookiesInfo'
import Search from './Search'
import Cart from './Cart'
import Forms from './Forms'
import Catalog from './Catalog'
import Share from './Share'
import ViewsController from './ViewsController'
import Gallery from './Gallery'
import PageNotFound from './PageNotFound'
import GoOnTop from './GoOnTop'

export default function installComponents(Vue) {
  Vue.use(ProfileManager)
  Vue.use(ProfileBlogger)
  Vue.use(ProfileCustomer)
  Vue.use(Currencies)
  Vue.use(UiElements)
  Vue.use(Pagination)
  Vue.use(Controls)
  Vue.use(Search)
  Vue.use(Cart)
  Vue.use(Forms)
  Vue.use(CookiesInfo)
  Vue.use(Catalog)
  Vue.use(Share)
  Vue.use(ViewsController)
  Vue.use(Gallery)
  Vue.use(PageNotFound)
  Vue.use(GoOnTop)
}
