<template lang="pug">
  include /mixins
  +b.change-type.I(
    @click.prevent='iconAppendFunc',
    :class="appendIcon"
  )
</template>
<script>
export default {
  name: 'AppendComponent',
  props: {
    appendIcon: {},
    iconAppendFunc: {},
  },
}
</script>
