const CustomerOrigin = () => import('@components/Cabinet/Customer/CustomerOrigin')
const CustomerOrders = () => import('@components/Cabinet/Customer/CustomerOrders')
const CustomerPersonal = () => import('@components/Cabinet/Customer/CustomerPersonal')
const CustomerTable = () => import('@components/Cabinet/Customer/Table/CustomerTable')
const CustomerTableBody = () => import('@components/Cabinet/Customer/Table/CustomerTableBody')

export default function installComponents(Vue) {
  Vue.component('CustomerOrigin', CustomerOrigin)
  Vue.component('CustomerOrders', CustomerOrders)
  Vue.component('CustomerPersonal', CustomerPersonal)
  Vue.component('CustomerTable', CustomerTable)
  Vue.component('CustomerTableBody', CustomerTableBody)
}
