import * as LINKS from '@router/Blogger/consts'

const BloggerOriginComponent = () => import('@components/Cabinet/Blogger/BloggerOrigin')
const BloggerPromocodesComponent = () => import('@components/Cabinet/Blogger/BloggerPromocodes')
const BloggerPersonalComponent = () => import('@components/Cabinet/Blogger/BloggerPersonal')

export const routes = [
  {
    path: LINKS.BLOGGER_BASE_PATH,
    redirect: LINKS.BLOGGER_PROMOCODES_PATH,
    component: BloggerOriginComponent,
    name: 'blogger-origin',
    meta: {
      userRole: 'blogger',
    },
    children: [
      {
        path: LINKS.BLOGGER_PROMOCODES_PATH,
        component: BloggerPromocodesComponent,
        name: 'blogger-promocodes',
      },
      {
        path: LINKS.BLOGGER_PERSONAL_PATH,
        component: BloggerPersonalComponent,
        name: 'blogger-personal',
      },
    ],
  },
]
