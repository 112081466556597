import { getCartInfo } from '@services/cart.service'

export const cart = {
  namespaced: true,
  state: {
    cartInfo: {},
    changeTrigger: false,
    endOfTimeTrigger: false,
  },
  actions: {
    async getCartInfo({ commit }) {
      return getCartInfo.execute().then(({ data: { item } }) => {
        // console.log(item, 'c@rtttttt')
        commit('SET_CART_INFORMATION', item)
      })
    },
  },
  mutations: {
    SET_CART_INFORMATION(state, result) {
      state.cartInfo = result
    },
    CHANGE_CART_INFO(state) {
      state.changeTrigger = !state.changeTrigger
    },
    HIDE_CART(state) {
      state.endOfTimeTrigger = !state.endOfTimeTrigger
    },
  },
}
