import Multiselect from 'vue-multiselect'
import { createHOCc } from 'vue-hoc'

export const multiselectDefaults = createHOCc({
  props: [
    'placeholder',
    'tagPlaceholder',
    'showLabels',
    'searchable',
    'multiple',
    'closeOnSelect',
    'hideSelected',
    'taggable',
  ],
}, {
  props: {
    openDirection() {
      return 'bottom'
    },
    placeholder() {
      return this.placeholder || this._('')
    },
    tagPlaceholder() {
      return this.tagPlaceholder || ''
    },
    showLabels() {
      return this.showLabels || false
    },
    searchable() {
      return this.searchable || false
    },
    multiple() {
      return this.multiple || false
    },
    closeOnSelect() {
      return this.closeOnSelect || false
    },
    hideSelected() {
      return this.hideSelected || false
    },
    taggable() {
      return this.taggable || true
    },
  },
})

export default multiselectDefaults(Multiselect)
