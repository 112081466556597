import * as LINKS from '@router/Customer/consts'

const CustomerOriginComponent = () => import('@components/Cabinet/Customer/CustomerOrigin')
const CustomerOrdersComponent = () => import('@components/Cabinet/Customer/CustomerOrders')
const CustomerPersonalComponent = () => import('@components/Cabinet/Customer/CustomerPersonal')

export const routes = [
  {
    path: LINKS.CUSTOMER_BASE_PATH,
    redirect: LINKS.CUSTOMER_ORDERS_PATH,
    component: CustomerOriginComponent,
    name: 'customer-origin',
    meta: {
      userRole: 'customer',
    },
    children: [
      {
        path: LINKS.CUSTOMER_ORDERS_PATH,
        component: CustomerOrdersComponent,
        name: 'customer-orders',
      },
      {
        path: LINKS.CUSTOMER_PERSONAL_PATH,
        component: CustomerPersonalComponent,
        name: 'customer-personal',
      },
    ],
  },
]
