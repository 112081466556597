import Vuex from 'vuex'
import { cart } from './modules/cart'

export function createStore() {
  return new Vuex.Store({
    deep: true,
    modules: {
      cart,
    },
  })
}
