<template lang="pug">
  date-picker.pointer(
    v-model="val"
    :valueType="valueType"
    :format='format'
    :placeholder="placeholder"
    :range="range"
    @input="$emit('emit-input', val)"

  )
    //- @change="$emit('emit-input', val)"
    //- :disabled-date="notBeforeToday"
</template>

<script>
import DatePicker from 'vue2-datepicker'

export default {
  name: 'date-pick',
  data() {
    return {
      val: this.value,
    }
  },
  components: {
    DatePicker,
  },
  props: {
    range: Boolean,
    format: String,
    valueType: String,
    value: {},
    placeholder: String,
  },
  watch: {
    value(nval) {
      this.val = nval
    },
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
  },
}
</script>
