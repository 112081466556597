import VueI18n from 'vue-i18n'
import installComponents from '@app/js/components'
import VueBem from '@aspectus/vue-bem'
import GridPlugin from '@aspectus/vue-grid'
import VModal from 'vue-js-modal'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueZoomer from 'vue-zoomer'

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(installComponents)
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(GridPlugin)
  Vue.use(VModal, {
    dynamicDefaults: {
      height: 'auto',
    },
  })
  Vue.use(VueSocialSharing)
  Vue.use(VueClipboard)
  Vue.use(VueZoomer)
}

export default { install }
