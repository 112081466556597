const CartInfo = () => import('./CartInfo')
const MobileCartInfo = () => import('./MobileCartInfo')
const CheckoutController = () => import('./Controller')
const Checkout = () => import('./Checkout')
const TicketPreview = () => import('./TicketPreview')
const AuthenticatedCustomer = () => import('./AuthenticatedCustomer')
const NotAuthenticatedCustomer = () => import('./NotAuthenticatedCustomer')
const PaymentMethod = () => import('./PaymentMethod')
const Promocode = () => import('./Promocode')
const Counter = () => import('./Counter')

export default function installComponents(Vue) {
  Vue.component('CartInfo', CartInfo)
  Vue.component('CheckoutController', CheckoutController)
  Vue.component('Checkout', Checkout)
  Vue.component('TicketPreview', TicketPreview)
  Vue.component('AuthenticatedCustomer', AuthenticatedCustomer)
  Vue.component('NotAuthenticatedCustomer', NotAuthenticatedCustomer)
  Vue.component('PaymentMethod', PaymentMethod)
  Vue.component('Promocode', Promocode)
  Vue.component('Counter', Counter)
  Vue.component('MobileCartInfo', MobileCartInfo)
}
