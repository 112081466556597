const Subscription = () => import('./Subscription')
const SpecifyEmail = () => import('./SpecifyEmail')
const NewCustomer = () => import('./NewCustomer')
const RegularCustomer = () => import('./RegularCustomer')
const AuthForm = () => import('./AuthForm')
const ConfirmPassword = () => import('./ConfirmPassword')

export default function installComponents(Vue) {
  Vue.component('Subscription', Subscription)
  Vue.component('SpecifyEmail', SpecifyEmail)
  Vue.component('NewCustomer', NewCustomer)
  Vue.component('RegularCustomer', RegularCustomer)
  Vue.component('AuthForm', AuthForm)
  Vue.component('ConfirmPassword', ConfirmPassword)
}
